import { Link } from 'gatsby'
import React from 'react'
import { colours } from './panel-colours'

const PreviewArticle = ({ article, i }) => {
  const date = new Date(article.date)

  return (
    <div className={`article-preview ${colours[i % 4]}`}>
      {article.date && (
        <h4>
          {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
        </h4>
      )}
      <h2>{article.title}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: article.excerpt.substring(0, 229) + '...',
        }}
        className="markdown"
      />
      <Link
        className="mock-button"
        to={article.slug}
        style={{ alignSelf: 'flex-end', paddingBottom: '8px' }}
      >
        Read More
      </Link>
    </div>
  )
}

export default PreviewArticle
