import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'
import PreviewArticle from '../components/UI/news/article-preview'

const NewsPage = ({ data, location }) => {
  const articles = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.cms === 'news'
  )

  const release = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.cms === 'press'
  )

  return (
    <Layout
      location={typeof window !== 'undefined' && location}
      crumbLabel="News"
    >
      <GatsbySeo
        title="Cleantech Investment News"
        description="Irbisio's round-up of news articles, from our industry and partners."
      />
      {/* <GridContent id="press" layout="--center-4" mode="dark-mode">
        <h4>Irbisio News</h4>
        {release.map((node, i) => {
          const article = {
            title: node.node.frontmatter.title,
            date: node.node.frontmatter.date,
            excerpt: node.node.excerpt,
            slug: node.node.fields.slug,
          }
          return (
            <div key={i}>
              <PreviewArticle article={article} />
            </div>
          )
        })}
      </GridContent> */}
      <GridContent id="news" layout="--center-4" mode="dark-mode" invert="true">
        <h4>cleantech investment news</h4>
        <h1>
          Irbisio's round-up of news articles, from our industry and partners.
        </h1>
        {articles.map((node, i) => {
          const article = {
            title: node.node.frontmatter.title,
            date: node.node.frontmatter.date,
            excerpt: node.node.excerpt,
            slug: node.node.fields.slug,
          }
          return <PreviewArticle key={i} i={i} article={article} />
        })}
      </GridContent>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 230)
          frontmatter {
            cms
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
